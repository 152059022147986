<template>
    <div>
        <div class="wrapper mx-auto relative">
            <div class="flex justify-between items-center px-4" style="height: 100px">
                <router-link :to="{ name: homeComponentName }">
                    <div>
                        <img class="w-40" src="img/css/logo.svg?3" />
                        <p class="text-xs text-gray-500 mt-2">v{{ version }}</p>
                    </div>
                </router-link>

                <div class="flex">
                    <language-picker />

                    <router-link
                        class="h4 text-base text-primary-normal ml-4 cursor-pointer py-2 px-3 border border-primary-normal rounded"
                        :to="{ name: profileRouteName }"
                        title="Profiel"
                        v-if="isLoggedIn && !$store.getters.isContractor"
                    >
                        <UserIcon />
                        <span class="ml-1">{{ $store.getters.currentUserFirstName }}</span>
                    </router-link>

                    <button
                        class="h4 text-base text-primary-normal ml-4 cursor-pointer py-2 px-3 border border-primary-normal rounded"
                        @click="logout"
                        v-if="isLoggedIn && $store.getters.isContractor"
                    >
                        <UserIcon />
                        <span class="ml-1" v-trans="'logout'">Log out</span>
                    </button>
                </div>
            </div>

            <div class="w-full bg-white px-4 py-3 md:px-8 md:py-7 xl:px-12 xl:py-10 rounded-lg md:shadow-lg">
                <transition mode="out-in" name="fade-in" appear>
                    <router-view />
                </transition>
            </div>

            <!--            <div class="flex justify-between mt-5">-->
            <!--            <router-link tag="button" :to="{name:'UiSystem'}" class="btn fixed bottom-0 right-0 mr-5 mb-5">UI System</router-link>-->
            <!--            </div>-->
        </div>

        <div class="ie-only absolute left-0 top-0 w-full h-full" v-if="showIEMessage">
            <div class="ie-backdrop absolute left-0 top-0 w-full h-full opacity-75 bg-white"></div>
            <div class="p-8 mx-auto m-5 bg-white shadow-lg rounded-lg w-1/3 text-center">
                <p v-trans="'melding-ie'">
                    Voor een optimale gebruikerservaring raden we aan om een andere browser te gebruiken dan Internet
                    Explorer
                </p>
                <button @click="showIEMessage = false" class="btn btn--primary mt-5" v-trans="'ok'">OK</button>
            </div>
        </div>
    </div>
</template>
<script>
import LanguagePicker from "../components/LanguagePicker";
import UserIcon from "vue-material-design-icons/Account";
import * as Cookies from "js-cookie";
import { Utils } from "../utils/utils";
import { ACTION_INIT_QR_CODE_SCANNERS, ACTION_LOG_OUT } from "@/store/constants";
import axios from "axios";

export default {
    name: "Online",
    components: {
        LanguagePicker,
        UserIcon,
    },
    data() {
        return {
            showIEMessage: false,
            version: "",
        };
    },

    created() {
        axios.get(`manifest.json?${Math.random()}`, { responseType: "json" }).then((response) => {
            this.version = response.data.version;
        });
    },
    mounted() {
        if (Utils.detectIE() !== false && typeof Cookies.get("ie-first-time") === "undefined") {
            this.showIEMessage = true;
            Cookies.set("ie-first-time", true);
        }
        setTimeout(() => {
            this.$store.dispatch(ACTION_INIT_QR_CODE_SCANNERS);
        }, 1000);
    },

    computed: {
        isLoggedIn() {
            return (
                this.$store.state.persons.currentContractorAdmin.id || this.$store.state.persons.currentContractor.id
            );
        },
        homeComponentName() {
            if (this.$store.state.persons.currentCompanyAdmin.id) {
                return "onlineAdmin";
            } else if (this.$store.state.persons.currentContractorAdmin.id) {
                return "onlineContractorAdmin";
            } else {
                return "onlineContractor";
            }
        },
        profileRouteName() {
            if (this.$store.state.persons.currentCompanyAdmin.id) {
                return "CompanyAdminProfile";
            } else if (this.$store.state.persons.currentContractorAdmin.id) {
                return "ContractorAdminMyProfile";
            } else if (this.$store.state.persons.currentContractor.id) {
                return "ContractorAdminMyProfile";
            } else {
                return "online";
            }
        },
    },
    methods: {
        logout() {
            this.$store.dispatch(ACTION_LOG_OUT);
        },
    },
};
</script>
